import React from "react";
import ReactDOM from "react-dom";
import GlobalStyle from "./global/globalStyles";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./global/scrollToTop";
import Theme from "./global/theme";
import Menu from "./nav/menu";
import ProtectedRoute from "./global/protectedRoute";
import reportWebVitals from "./reportWebVitals";
import Auth0ProviderWithHistory from "./global/auth0ProviderWithHistory";

import App from "./App";
import About from "./pages/about";
import Contact from "./pages/contact";
import VWFS from "./pages/vwfs";
import Devyce from "./pages/devyce";
import Vaughan from "./pages/vaughan";
import RecNow from "./pages/recNow";
import Further from "./pages/further";
import EvMe from "./pages/ev";
import PLPB from "./pages/plpb";
import DesignOps from "./pages/designOps";
import RAC from "./pages/rac";
import TCO from "./pages/tco";
import MyRAC from "./pages/myrac";
import JPP from "./pages/jpp";
import Arr from "./pages/arr";
import NotFound from "./pages/notFound";
import Callback from "./pages/callback";

const Routing = () => {
  return (
    <Theme>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ScrollToTop>
            <GlobalStyle />
            <Menu />

            <Switch>
              <Route exact path="/" component={App} />
              <Route path="/pages/about" component={About} />
              <Route path="/pages/contact" component={Contact} />

              <Route path="/pages/vwfs" component={VWFS} />
              <Route path="/pages/devyce" component={Devyce} />
              <Route path="/pages/vaughan" component={Vaughan} />
              <ProtectedRoute path="/pages/recNow" component={RecNow} />
              <Route path="/pages/further" component={Further} />
              <ProtectedRoute path="/pages/ev" component={EvMe} />
              <Route path="/pages/plpb" component={PLPB} />
              <Route path="/pages/designOps" component={DesignOps} />
              <ProtectedRoute path="/pages/rac" component={RAC} />
              <ProtectedRoute path="/pages/tco" component={TCO} />
              <ProtectedRoute path="/pages/myrac" component={MyRAC} />
              <ProtectedRoute path="/pages/jpp" component={JPP} />
              <ProtectedRoute path="/pages/arr" component={Arr} />

              <Route path="/pages/callback" component={Callback} />
              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Theme>
  );
};

export default Routing;

ReactDOM.render(<Routing />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
