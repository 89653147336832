import React from "react";
import styled from "styled-components";
import device from "../global/mediaQueries";
import HeroWrap from "../layout/heroWrap";
import FootContent from "../layout/footContent";
import MenuIconsList from "../nav/menuIconsList";
import MenuDivider from "../layout/menuDivider";
import IconBtn from "../nav/iconBtn";
import dribbble from "../assets/icons/dribbbleBlack.svg";
import linked from "../assets/icons/linkedinBlack.svg";
import behance from "../assets/icons/behanceBlack.svg";
import mail from "../assets/icons/mail.svg";
import FootBG from "../assets/img/heroFoot.svg";

const FootLinksWrap = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.laptop} {
    flex-direction: column-reverse;
  }
`;

function Foot() {
  return (
    <div>
      <HeroWrap img={FootBG}>
        <small></small>
        <FootContent>
          <h1>
            <b>Thanks for stopping by</b>
          </h1>
          <FootLinksWrap>
            <IconBtn
              label="thomasweaverdesign@gmail.com"
              icon={mail}
              alt="behance"
            />
            <MenuDivider />

            <MenuIconsList>
              <li>
                <a
                  href="https://dribbble.com/furthertype"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconBtn label="" icon={dribbble} alt="dribbble" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/thomas-weaver-design/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconBtn label="" icon={linked} alt="linked" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.behance.net/thomasweaverdesign"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconBtn label="" icon={behance} alt="behance" />
                </a>
              </li>
            </MenuIconsList>
          </FootLinksWrap>
        </FootContent>
        <small>© All rights wronged. 2024 TWD</small>
      </HeroWrap>
    </div>
  );
}

export default Foot;
